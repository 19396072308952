import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://gtmember.${config.devbase}`
config.prodURL = 'https://cms.gtmembership.co.nz'
config.siteURL = 'https://app.gtmembership.co.nz'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://6c3d58d887f840798e04432e231a16c2@glitchtip.logger.jm1.au/20'

config.login.code_only = true
config.signUp.ask_date_of_birth = true
config.profile.edit_date_of_birth = true
config.profile.edit_emergency_contact = true
config.profile.edit_drivers_licence = true
config.profile.edit_company_name = true
config.profile.edit_company_phone = true
config.profile.edit_trading_name = true
config.profile.manage_addresses = true

export { config }
