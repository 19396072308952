<template>
  <div class="mx-auto max-w-lg p-4">
    <div class="py-4">
      <img class="mx-auto" src="../images/logo.png">
    </div>
    <div v-if="membershipStore.needsRenewal" class="text-center text-2xl">
      Your membership has expired
    </div>
    <div v-else-if="membershipStore.valid_to && membershipStore.canRenew" class="flex flex-col items-center">
      <span class="text-xl text-gray-200">Your membership will expire on</span>
      <span class="text-4xl">{{ membershipStore.valid_to_formatted }}</span>
    </div>
    <cgn-alert-danger v-if="profileInComplete">
      Your profile is not complete
      <template #btn>
        <cgn-button color-danger url="/profile">
          Edit Profile
        </cgn-button>
      </template>
    </cgn-alert-danger>

    <div v-else class="grid cursor-pointer select-none grid-cols-2 gap-2 py-2 text-base text-brand-500 xs:text-xl">
      <router-link v-if="false && membershipStore.canRenew" to="/renew" class="col-span-2 flex items-center justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:cash /> Renew your membership now</span>
      </router-link>
      <router-link v-if="!membershipStore.needsRenewal" to="/membershipcard" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:credit-card /> Membership card</span>
      </router-link>
      <router-link to="/calendar" class="flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:calendar /> Events</span>
      </router-link>
      <router-link to="/links" class="flex justify-center bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:link /> Important Links</span>
      </router-link>
      <router-link to="/profile" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1">My Profile</span>
      </router-link>
      <router-link to="/logout" class="absolute bottom-0 right-0">
        <span class="flex items-center gap-1">
          <i-heroicons-solid:logout />
        </span>
      </router-link>
    </div>
    <cgn-ios-pwa-prompt />
  </div>
</template>

<script setup lang="ts">
import { CognitoAddress } from '~cognito/models/Cognito/Address'

const membershipStore = useClubMemberStore()
const userStore = useUserStore()
const profileInComplete = computedAsync(async () => {
  if (!userStore.user.first_name) {
    return true
  }
  if (!userStore.user.last_name) {
    return true
  }
  if (!userStore.user.mobile_phone) {
    return true
  }
  if (!userStore.user.date_of_birth) {
    return true
  }
  if (userStore.user.thumbnail.includes('gravatar')) {
    return true
  }
  const addresses = await new CognitoAddress().find_many({
    user_id: userStore.user.id,
  })
  if (addresses.data.length == 0) {
    return true
  }
  return false
})

onMounted(() => {
  membershipStore.refresh()
})
</script>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
